/**
 * Created by katarinababic on 27.1.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '../../Theme/Theme';
import { PointsModelRule } from '../../Model/Engagement/Rewards/PointsModelRule';
import { useRootStore } from '../../Store/useRootStore';
import { useTrans } from '../../Store/System/LocalizationStore';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

const DotContainer = styled.div<{ completed?: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.completed ? Theme.Colors.primary : Theme.Colors.lightGrey)};
`;

const RuleCard = styled.div`
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 16px;
  border-width: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Slightly more pronounced on hover */
  }
`;

const InnerRuleCardContainer = styled.div`
  text-align: left;
`;

const Title = styled.span`
  font-weight: 600;
`;

const Description = styled.span`
  color: ${Theme.Colors.charcoal};
  margin-top: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const PointsIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const GreenCheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#fff" />
  </svg>
);

export type AccountPointsSectionPointRuleEntryProps = {
  rule: PointsModelRule;
  index: number;
};

export const AccountPointsSectionPointRuleEntry: React.FC<AccountPointsSectionPointRuleEntryProps> = observer(
  ({ rule, index }) => {
    const { language } = useRootStore();
    const { t } = useTrans();
    const navigate = useNavigate();

    const [ruleLink, setRuleLink] = React.useState<string | undefined>(undefined);

    const ruleNumberText = (index + 1).toString().padStart(2, '0');
    const collectedPointsText =
      rule.type === 'stepped'
        ? t('motivationPointRuleEntry.pointsOf', {
            defaultValue: '{{collected}} of {{total}}',
            collected: rule.collectedExperiencePoints,
            total: rule.totalExperiencePoints,
          })
        : `${rule.totalExperiencePoints}`;

    const getRuleLink = React.useCallback(() => {
      switch (rule.appActionLink) {
        case 'https://coach.kinastic.com/explore-tab/mental':
          return '/mental-health';
        case 'https://coach.kinastic.com/explore-tab/nutrition':
          return '/nutrition';
        case 'https://coach.kinastic.com/explore-tab/academy':
          return '/academy';
        case 'https://coach.kinastic.com/log-activity':
          return '/move';
        case 'https://coach.kinastic.com/feed':
          return '/academy/library';
        default:
          return undefined;
      }
    }, [rule]);

    useEffect(() => {
      const link = getRuleLink();
      setRuleLink(link);
    }, [getRuleLink]);

    const handleClick = React.useCallback(() => {
      if (ruleLink && !rule.completed) {
        navigate(ruleLink);
      }
    }, [navigate, ruleLink]);

    return (
      <Container>
        <DotContainer completed={rule.completed}>
          {rule.completed ? <GreenCheckIcon /> : <span>{ruleNumberText}</span>}
        </DotContainer>

        <RuleCard as={ruleLink && !rule.completed ? 'button' : 'div'} onClick={handleClick}>
          <InnerRuleCardContainer>
            <Title>{rule.getName(language.language)}</Title>
            <IconContainer>
              <PointsIcon src={require('../../Assets/Images/heart.png')} alt="Points" />
              <span>{collectedPointsText}</span>
            </IconContainer>
            {rule.description && <Description>{rule.getDescription(language.language)}</Description>}
          </InnerRuleCardContainer>
        </RuleCard>
      </Container>
    );
  },
);
