/**
 * Created by neo on 01.09.22.
 */
import { computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { languagePriority } from '../../LocalizedNamedEntity';

export type EarnModelProcessResultJson = {
  ruleId: string;
  ruleIdentifier: string;
  requestId: string;
  processedAt: string;
  pointsCollected: number;
  totalPointsCollected: number;
  rewardLink?: string;
  instantMessage: LocalizedValueJson[];
  ruleName: LocalizedValueJson[];
  estimatedCoinsCollected: number;
  totalEstimatedCoinsCollected: number;
};

export class EarnModelProcessResult {
  @observable
  ruleId = '';
  @observable
  ruleIdentifier = '';
  @observable
  requestId = '';
  @observable
  processedAt = new Date();
  @observable
  pointsCollected = 0;
  @observable
  totalPointsCollected = 0;
  @observable
  rewardLink: string | undefined;
  @observable
  instantMessage: LocalizedValue[] = [];
  @observable
  ruleName: LocalizedValue[] = [];
  @observable
  estimatedCoinsCollected = 0;
  @observable
  totalEstimatedCoinsCollected = 0;

  constructor(json?: Partial<EarnModelProcessResultJson>) {
    if (json) {
      this.ruleId = json.ruleId ?? '';
      this.ruleIdentifier = json.ruleIdentifier ?? '';
      this.requestId = json.requestId ?? '';
      this.processedAt = json.processedAt ? new Date(json.processedAt) : new Date();
      this.pointsCollected = json.pointsCollected ?? 0;
      this.totalPointsCollected = json.totalPointsCollected ?? 0;
      this.rewardLink = json.rewardLink;
      this.instantMessage = json.instantMessage?.map((l) => new LocalizedValue(l)) ?? [];
      this.ruleName = json.ruleName?.map((l) => new LocalizedValue(l)) ?? [];
      this.estimatedCoinsCollected = json.estimatedCoinsCollected ?? 0;
      this.totalEstimatedCoinsCollected = json.totalEstimatedCoinsCollected ?? 0;
    }
  }

  getRuleName(lang: string): string {
    return this.ruleName.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultRuleName;
  }

  @computed
  get defaultRuleName(): string {
    for (const lang of languagePriority) {
      const entry = this.ruleName.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.ruleName[0];
    return first?.value ?? '';
  }
}
