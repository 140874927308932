/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewHostCard } from '../ExploreContentViewHostCard';
import { ExploreContentViewOnlineMeetingContent } from './ExploreContentViewOnlineMeetingContent';
import { ExploreContentViewOnlineMeetingReminder } from './ExploreContentViewOnlineMeetingReminder';
import { useEffect } from 'react';
import { useRootStore } from '../../../Store/useRootStore';
import { ExploreContentViewOnlineMeetingMediaContainer } from './ExploreContentViewOnlineMeetingMediaContainer';
import { LanguageDisclaimer } from '../LanguageDisclaimer';

export type ExploreContentViewOnlineMeetingProps = {
  entry: OnlineMeetingEntry;
};

export const ExploreContentViewOnlineMeeting: React.FC<ExploreContentViewOnlineMeetingProps> = observer(({ entry }) => {
  const { analytics } = useRootStore();

  const { canJoinMeeting, nextDate } = entry;
  const eventLogged = React.useRef(false);

  useEffect(() => {
    if (canJoinMeeting && !eventLogged.current) {
      eventLogged.current = true;
      analytics.logEvent('webinar_joined', {
        objectId: entry.id,
        objectType: entry.type,
        url: entry.meetingLink,
      });
    }
  }, [analytics, canJoinMeeting, entry]);

  return (
    <Row>
      <Col xs={12} style={{ paddingBottom: 32 }}>
        <Container>
          <ExploreContentViewOnlineMeetingMediaContainer entry={entry} />
        </Container>
      </Col>
      <Col>
        <Container>
          <Row>
            <Col xs={12} md={7} xl={6} style={{ paddingTop: 16 }}>
              <ExploreContentViewOnlineMeetingContent entry={entry} />
            </Col>
            <Col xs={12} md={5} xl={3} style={{ paddingTop: 16, paddingBottom: 16 }}>
              {nextDate && <ExploreContentViewOnlineMeetingReminder entry={entry} />}
            </Col>
            <Col xs={12} md={7} xl={3}>
              <ExploreContentViewHostCard host={entry.hosts[0]} />
              {/*<LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
});
