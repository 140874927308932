/**
 * Created by katarinababic on 22.11.24.
 */

import { action, computed, observable, runInAction } from 'mobx';
import { BreathingEntry } from './BreathingEntry';
import { BreathingTechniqueStep } from './BreathingTechniqueStep';
import { translate } from '../../Store/System/LocalizationStore';
import { ActivityLog } from '../Activity/ActivityLog';

export type MusicFile = { key: string; title: string; source: () => ReturnType<typeof require> };

export const TimeoutSoundSelection: MusicFile[] = [
  {
    title: translate('explore.timeoutTab.noSound'),
    key: 'no-sound',
    source: () => undefined,
  },
  {
    title: 'Distant Thunder and Water Dripping from Forest Canopy',
    key: 'Distant-Thunder-and-Water-Dripping-from-Forest-Canopy.mp3',
    source: () => require('../../Assets/Audio/Breathing/Distant_Thunder_and_Water_Dripping_from_Forest_Canopy.mp3'),
  },
  {
    title: 'Exotic Swamp Birds',
    key: 'Exotic-Swamp-Birds.mp3',
    source: () => require('../../Assets/Audio/Breathing/Exotic_Swamp_Birds.mp3'),
  },
  {
    title: 'Ocean Waves',
    key: 'Ocean-Waves_1.mp3',
    source: () => require('../../Assets/Audio/Breathing/Ocean_Waves.mp3'),
  },
  {
    title: 'Light Rain Falling on Forest Floor',
    key: 'Light-Rain-Falling-on-Forest-Floor_1.mp3',
    source: () => require('../../Assets/Audio/Breathing/Light_Rain_Falling_on_Forest_Floor.mp3'),
  },
  {
    title: 'Morning Bird Songs',
    key: 'Morning Bird Songs.mp3',
    source: () => require('../../Assets/Audio/Breathing/Morning_Bird_Songs.mp3'),
  },
  {
    title: 'Mountain Atmosphere',
    key: 'Mountain-Atmosphere_1.mp3',
    source: () => require('../../Assets/Audio/Breathing/Mountain_Atmosphere.mp3'),
  },
];

export class BreathingSession {
  @observable
  entry: BreathingEntry;
  @observable
  singleCycleDuration: number;
  @observable
  duration: number = 0;
  @observable
  startDateTime: Date | undefined;
  @observable
  endDateTime: Date | undefined;
  @observable
  currentCommand: BreathingTechniqueStep | undefined;
  @observable
  selectedSound: MusicFile | undefined;
  private timeout?: ReturnType<typeof setTimeout>;

  constructor(entry: BreathingEntry) {
    this.entry = entry;
    this.singleCycleDuration = this.entry.technique.reduce((total, t) => total + t.duration, 0);
    this.duration = Math.floor(60 / this.singleCycleDuration) * this.singleCycleDuration;
    this.selectedSound =
      TimeoutSoundSelection.find((m) => entry.backgroundVideos.some((v) => v.name === m.key)) ??
      TimeoutSoundSelection.find((m) => m.key === 'Distant-Thunder-and-Water-Dripping-from-Forest-Canopy.mp3');
  }

  @action
  setDuration(value: number) {
    /**
     * if the technique is 4-7-8 and val is 60 seconds
     * => totalSeconds = 19
     * => totalCycles = Math.floor(60/19) = 3
     * => duration = 3 * 19 = 57
     * */
    this.duration = Math.floor(value / this.singleCycleDuration) * this.singleCycleDuration;
  }

  @action
  startSession(): Date {
    // Reset the session if it's completed to allow restarting
    if (this.completed) {
      runInAction(() => {
        this.startDateTime = undefined;
        this.endDateTime = undefined;
        this.currentCommand = undefined;
        this.timeout && clearTimeout(this.timeout);
      });
    }

    if (!this.startDateTime) {
      this.startDateTime = new Date();
      this.scheduleCommands();
    }
    return this.startDateTime;
  }

  @action
  endSession(): Date | undefined {
    this.timeout && clearTimeout(this.timeout);
    if (this.startDateTime && !this.endDateTime) {
      this.endDateTime = new Date();
    }
    return this.endDateTime;
  }

  @action
  setSelectedSound(musicFile?: MusicFile) {
    this.selectedSound = musicFile;
  }

  createActivityLog(): ActivityLog | undefined {
    if (this.startDateTime && this.endDateTime) {
      return this.entry.createActivityLog(this.startDateTime, this.endDateTime, {
        PERCENTAGE_COMPLETED: this.percentageCompleted,
        TOTAL_BREATHS: this.totalDeepBreaths,
      });
    }
  }

  /*
    multiplies the technique array as many times as the
    session should be repeated to get the final array of commands
    */
  private getMultipliedCommands(): BreathingTechniqueStep[] {
    return [...Array(this.totalCycles)].reduce(
      (prev) => [...prev, ...this.entry.technique],
      [] as BreathingTechniqueStep[],
    );
  }

  /* every next command should happen after command.duration amount of time */
  private scheduleCommands() {
    const multipliedCommands = this.getMultipliedCommands();
    this.scheduleCommand(multipliedCommands, 0);
  }

  private scheduleCommand(commands: BreathingTechniqueStep[], index: number) {
    const command = commands[index];
    if (command) {
      const nextIndex = index + 1;
      const nextCommand = commands[index + 1];
      runInAction(() => (this.currentCommand = command));
      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (nextCommand) {
          this.scheduleCommand(commands, nextIndex);
        } else {
          this.endSession();
        }
      }, command.duration * 1000);
    }
  }

  @computed
  get totalCycles(): number {
    return Math.floor(this.duration / this.singleCycleDuration);
  }

  @computed
  get totalDuration(): number {
    return (this.endDateTime ?? new Date()).valueOf() - (this.startDateTime ?? new Date()).valueOf();
  }

  @computed
  get percentageCompleted(): number {
    return Math.min(((this.totalDuration / 1000) * 100) / this.duration, 100);
  }

  @computed
  get totalDeepBreaths(): number {
    return Math.max(Math.floor(Math.floor(this.totalDuration / 1000) / this.singleCycleDuration), 1);
  }

  @computed
  get notStarted(): boolean {
    return !this.startDateTime && !this.endDateTime;
  }

  @computed
  get started(): boolean {
    return !!this.startDateTime;
  }

  @computed
  get active(): boolean {
    return !!this.startDateTime && !this.endDateTime;
  }

  @computed
  get completed(): boolean {
    return !!this.startDateTime && !!this.endDateTime;
  }
}
