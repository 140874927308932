/**
 * Created by katarinababic on 21.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { KinasticAudioPlayer } from '../KinasticAudioPlayer';
import { useRootStore } from '../../../Store/useRootStore';
import { MeditationEntry } from '../../../Model/Explore/MeditationEntry';
import dayjs from 'dayjs';

const Container = styled.div`
  @media (min-width: 600px) {
    height: 360px;
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }
`;

export type ExploreContentViewMeditationPlayerProps = {
  entry: MeditationEntry;
};

export const ExploreContentViewMeditationPlayer: React.FC<ExploreContentViewMeditationPlayerProps> = observer(
  ({ entry }) => {
    const { language, engagement, authentication, analytics } = useRootStore();

    const audioTracks = entry.narrators.map((n) => n.getAudioTrack(language.language));
    const backgroundImage = entry?.getImage(language.language)?.largeOrMediumOrSmallestUriObject.uri;

    const handleCreateActivityLog = React.useCallback(
      (watchedDurationInSeconds: number, totalDurationInSeconds: number) => {
        const { athlete } = authentication;

        const percentageCompleted = Math.min(1, watchedDurationInSeconds / totalDurationInSeconds);

        if (athlete && watchedDurationInSeconds > 0) {
          const activityLog = entry.createActivityLog(
            dayjs().subtract(watchedDurationInSeconds, 'seconds').toDate(),
            new Date(),
            {
              DURATION: watchedDurationInSeconds,
              PERCENTAGE_COMPLETED: percentageCompleted,
            },
          );

          engagement
            .sendActivityLogged(activityLog)
            .then((res) => {
              // Show the modal but don't wait for it to close
              engagement.showPointsModalPromise(res).catch((error) => {
                console.error('Failed to show points modal:', error);
              });
              // Save activity log and log analytics immediately
              return activityLog.save();
            })
            .then(() => {
              analytics.logEvents(['meditationSession_finished', 'explore_session_finished'], {
                id: entry.id,
                type: entry.type,
                totalDuration: totalDurationInSeconds,
                durationInSeconds: watchedDurationInSeconds,
                percentageCompleted,
                timestamp: Date.now(),
                name: entry.defaultName,
              });
            })
            .catch((error) => {
              console.error('Error in activity log processing:', error);
            });
        }
      },
      [authentication, engagement, entry, analytics],
    );

    return (
      <Container>
        <KinasticAudioPlayer
          audioTracks={audioTracks}
          backgroundImage={backgroundImage}
          onWatchDuration={handleCreateActivityLog}
        />
      </Container>
    );
  },
);
