/**
 * Created by katarinababic on 22.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { BreathingEntry } from '../../../Model/Explore/BreathingEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { MarkdownRenderer } from '../../Markdown/MarkdownRenderer';
import { BreathingExerciseDurationSelection } from './BreathingExerciseDurationSelection';
import { BreathingSession } from '../../../Model/Explore/BreathingSession';
import { BreathingExerciseBackgroundSound } from './BreathingExerciseBackgroundSound';
import { BreathingExerciseProgress } from './BreathingExerciseProgress';
import { useEffect } from 'react';
import { reaction, runInAction } from 'mobx';

const Title = styled.h1``;
const Subtitle = styled.h4`
  padding-bottom: 16px;
`;

export type ExploreContentViewBreathingProps = {
  entry: BreathingEntry;
};

export const ExploreContentViewBreathing: React.FC<ExploreContentViewBreathingProps> = observer(({ entry }) => {
  const { language, analytics, authentication, engagement } = useRootStore();
  const { t } = useTrans();

  const title = entry.getName(language.language);
  const technique = React.useMemo(() => entry.technique.map((t) => t.duration).join('-') || '4-4-4', [entry]);
  const description = entry.getDescription(language.language);

  const [breathingSession, setBreathingSession] = React.useState<BreathingSession>(new BreathingSession(entry));

  const backgroundSoundRef = React.useRef<HTMLAudioElement | null>(null);

  const handleStartSession = React.useCallback(() => {
    runInAction(() => breathingSession.startSession());
  }, [breathingSession]);

  const recreateBreathingSession = React.useCallback(() => {
    if (backgroundSoundRef.current) {
      backgroundSoundRef.current.pause();
      backgroundSoundRef.current.currentTime = 0;
      backgroundSoundRef.current = null;
    }
    setBreathingSession(new BreathingSession(entry));
  }, [entry]);

  useEffect(() => {
    const soundFile = breathingSession.selectedSound?.source();

    if (backgroundSoundRef.current) {
      backgroundSoundRef.current.pause();
      backgroundSoundRef.current.currentTime = 0;
      backgroundSoundRef.current = null;
    }

    if (soundFile) {
      backgroundSoundRef.current = new Audio(soundFile);
      backgroundSoundRef.current.loop = true;
    } else {
      // if 'no-sound' is selected
      backgroundSoundRef.current = null;
    }

    if (breathingSession.active && backgroundSoundRef.current) {
      backgroundSoundRef.current.play();
    }

    return () => {
      if (backgroundSoundRef.current) {
        backgroundSoundRef.current.pause();
        backgroundSoundRef.current.currentTime = 0;
        backgroundSoundRef.current = null;
      }
    };
  }, [breathingSession.active, breathingSession.selectedSound]);

  useEffect(
    () =>
      reaction(
        () => breathingSession.completed,
        (completed) => {
          const { athlete } = authentication;
          if (completed) {
            const activityLog = breathingSession.createActivityLog();
            if (athlete) {
              if (activityLog) {
                engagement
                  .sendActivityLogged(activityLog)
                  .then((res) => {
                    // Show the modal but don't wait for it to close
                    engagement.showPointsModalPromise(res).catch((error) => {
                      console.error('Failed to show points modal:', error);
                    });
                    // Save activity log and log analytics immediately
                    return activityLog.save();
                  })
                  .then(() => {
                    analytics.logEvents(['breathingSession_finished', 'explore_session_finished'], {
                      id: breathingSession.entry.id,
                      type: breathingSession.entry.type,
                      totalDuration: breathingSession.totalDuration,
                      percentageCompleted: breathingSession.percentageCompleted,
                      technique: breathingSession.entry.technique,
                      timestamp: Date.now(),
                      name: entry.defaultName,
                    });
                  })
                  .catch((error) => {
                    console.error('Error in activity log processing:', error);
                  });
              }
            }
          }
        },
        { name: 'log breathing session' },
      ),
    [authentication, breathingSession, analytics, engagement, entry],
  );

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <BreathingExerciseProgress
            activeSession={breathingSession}
            onStart={handleStartSession}
            onStop={recreateBreathingSession}
          />
        </Col>
      </Row>
      {!breathingSession.active && (
        <Row>
          <Col xs={12}>
            <BreathingExerciseDurationSelection breathingSession={breathingSession} />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <Title>{title}</Title>
          <Subtitle>
            {t('explore.breathing.technique')}: {technique}
          </Subtitle>
          <MarkdownRenderer text={description} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <BreathingExerciseBackgroundSound breathingSession={breathingSession} />
        </Col>
      </Row>
    </Container>
  );
});
