/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewActivityWorkoutVideoPlayer } from './ExploreContentViewActivityWorkoutVideoPlayer';
import { ExploreContentViewActivityWorkoutContent } from './ExploreContentViewActivityWorkoutContent';
import { ExploreContentViewHostCard } from '../ExploreContentViewHostCard';
import { ActivityWorkoutEntry } from '../../../Model/Explore/ActivityWorkoutEntry';
import { LanguageDisclaimer } from '../LanguageDisclaimer';

export type ExploreContentViewActivityWorkoutProps = {
  entry: ActivityWorkoutEntry;
};

export const ExploreContentViewActivityWorkout: React.FC<ExploreContentViewActivityWorkoutProps> = observer(
  ({ entry }) => {
    return (
      <Row>
        <Col xs={12} style={{ paddingBottom: 32 }}>
          <Container>
            <ExploreContentViewActivityWorkoutVideoPlayer entry={entry} />
          </Container>
        </Col>
        <Col>
          <Container>
            <Row>
              <Col xs={12} md={7} xl={9} style={{ paddingTop: 16 }}>
                <ExploreContentViewActivityWorkoutContent entry={entry} />
              </Col>
              <Col xs={12} md={5} xl={3}>
                <ExploreContentViewHostCard host={entry.instructor} />
                {/*<LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  },
);
