/**
 * Created by katarinababic on 24.1.25.
 */
import styled from '@emotion/styled';

export const VideoPlayerContainer = styled.div<{ backgroundImageUrl?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: ${({ backgroundImageUrl }) => (backgroundImageUrl ? `url('${backgroundImageUrl}')` : 'none')};
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 16px;

  @media (min-width: 600px) {
    height: 360px;
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }
`;
