/**
 * Created by neo on 17.10.22.
 */
import { observable } from 'mobx';

export type ShopUrlResponseJson = {
  shopUrl: string;
  baseUrl: string;
  refreshLoginUrl: string;
  expiresAt: string;
};

export class ShopUrlResponse {
  @observable
  shopUrl: string = '';
  @observable
  baseUrl: string = '';
  @observable
  refreshLoginUrl = '';
  @observable
  expiresAt = new Date();

  constructor(json?: Partial<ShopUrlResponseJson>) {
    if (json) {
      this.shopUrl = json.shopUrl ?? '';
      this.baseUrl = json.baseUrl ?? '';
      this.refreshLoginUrl = json.refreshLoginUrl ?? '';
      this.expiresAt = json.expiresAt ? new Date(json.expiresAt) : new Date();
    }
  }
}
