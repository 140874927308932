/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewVideoPlayer } from './ExploreContentViewVideoPlayer';
import { ExploreContentViewVideoContent } from './ExploreContentViewVideoContent';
import { VideoEntry } from '../../../Model/Explore/VideoEntry';
import { ExploreContentViewHostCard } from '../ExploreContentViewHostCard';
import { LanguageDisclaimer } from '../LanguageDisclaimer';

export type ExploreContentViewVideoProps = {
  entry: VideoEntry;
};

export const ExploreContentViewVideo: React.FC<ExploreContentViewVideoProps> = observer(({ entry }) => {
  return (
    <Row>
      <Col xs={12} style={{ paddingBottom: 32 }}>
        <Container>
          <ExploreContentViewVideoPlayer entry={entry} />
        </Container>
      </Col>
      <Col>
        <Container>
          <Row>
            <Col xs={12} md={7} xl={9} style={{ paddingTop: 16 }}>
              <ExploreContentViewVideoContent entry={entry} />
            </Col>
            <Col xs={12} md={5} xl={3}>
              <ExploreContentViewHostCard host={entry.instructor} />
              {/*<LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
});
