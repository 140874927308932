/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewHostCard } from '../ExploreContentViewHostCard';
import { LanguageDisclaimer } from '../LanguageDisclaimer';
import { ExploreContentViewWebinarContent } from './ExploreContentViewWebinarContent';
import { ExploreContentViewWebinarReminder } from './ExploreContentViewWebinarReminder';
import { ExploreContentViewWebinarVideoPlayer } from './ExploreContentViewWebinarVideoPlayer';

export type ExploreContentViewWebinarProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinar: React.FC<ExploreContentViewWebinarProps> = observer(({ entry }) => {
  const { nextDate } = entry;

  return (
    <Row>
      <Col xs={12} style={{ paddingBottom: 32 }}>
        <Container>
          <ExploreContentViewWebinarVideoPlayer entry={entry} />
        </Container>
      </Col>
      <Col>
        <Container>
          <Row>
            <Col xs={12} md={7} xl={6} style={{ paddingTop: 16 }}>
              <ExploreContentViewWebinarContent entry={entry} />
            </Col>
            <Col xs={12} md={5} xl={3} style={{ paddingTop: 16, paddingBottom: 16 }}>
              {nextDate && <ExploreContentViewWebinarReminder entry={entry} />}
            </Col>
            <Col xs={12} md={7} xl={3}>
              <ExploreContentViewHostCard host={entry.hosts[0]} />
              {/*<LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
});
