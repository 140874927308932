/**
 * Created by neo on 21.07.23
 */
import { observable } from 'mobx';
import { LocalizedEntity, LocalizedEntityJson } from '../../LocalizedEntity';

export type PointsModelRuleJson = LocalizedEntityJson & {
  type: string;
  totalExperiencePoints: number;
  totalRewardCoins: number;
  collectedRewardCoins: number;
  collectedExperiencePoints: number;
  completed: boolean;
  canBeCompleted: boolean;
  allowInChallenge: boolean;
  appActionLink?: string;
};

export class PointsModelRule extends LocalizedEntity {
  @observable
  type = 'simple';
  @observable
  totalRewardCoins = 0;
  @observable
  totalExperiencePoints = 0;
  @observable
  collectedRewardCoins = 0;
  @observable
  collectedExperiencePoints = 0;
  @observable
  completed = false;
  @observable
  canBeCompleted = false;
  @observable
  allowInChallenge = false;
  @observable
  appActionLink: string | undefined;

  constructor(json: PointsModelRuleJson) {
    super(json);
    if (json) {
      this.type = json.type ?? 'simple';
      this.completed = json.completed ?? false;
      this.canBeCompleted = json.canBeCompleted ?? false;
      this.totalRewardCoins = json.totalRewardCoins ?? 0;
      this.totalExperiencePoints = json.totalExperiencePoints ?? 0;
      this.collectedRewardCoins = json.collectedRewardCoins ?? 0;
      this.collectedExperiencePoints = json.collectedExperiencePoints ?? 0;
      this.allowInChallenge = json.allowInChallenge ?? false;
      this.appActionLink = json.appActionLink;
    }
  }
}
