/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../Store/useRootStore';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { KinasticVideoPlayer } from '../KinasticVideoPlayer';
import { EMPTY_ARRAY } from '../../../Utils/Constants';
import { ActivityLog } from '../../../Model/Activity/ActivityLog';
import { VideoPlayerContainer } from '../../VideoPlayerContainer';

const OverlayContainer = styled.div`
  position: absolute;
  padding: 16px;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
  text-align: center;
  min-width: 120px;
`;

const Month = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Day = styled.div`
  font-size: 32px;
`;

export type ExploreContentViewWebinarVideoPlayerProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinarVideoPlayer: React.FC<ExploreContentViewWebinarVideoPlayerProps> = observer(
  ({ entry }) => {
    const { language, engagement, analytics } = useRootStore();

    const nextMeetingDate = entry.nextStartDate;
    const hasStarted = !dayjs().isBefore(nextMeetingDate);
    console.log('nextMeetingDate', nextMeetingDate);
    const videoMedias =
      hasStarted && entry.videos
        ? entry.videos
        : !hasStarted && entry.previewVideos
        ? entry.previewVideos
        : EMPTY_ARRAY;
    const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

    const handleCreateActivityLog = React.useCallback(
      (watchedDurationInSeconds: number, totalDurationInSeconds: number) => {
        // only log activity if there's an actual video to watch
        if (videoMedias.length > 0 && watchedDurationInSeconds > 0) {
          const percentageCompleted = Math.min(1, watchedDurationInSeconds / totalDurationInSeconds) * 100;

          const activityLog = new ActivityLog({
            type: 'webinar',
            activityId: '496418af-858d-48d2-b0eb-affa6c853e67',
            startDate: dayjs().subtract(watchedDurationInSeconds, 'seconds').toISOString(),
            endDate: dayjs().toISOString(),
            data: {
              DURATION: watchedDurationInSeconds,
            },
            source: {
              source: 'kinastic',
              sourceType: 'explore-entry',
              sourceId: entry.id,
            },
          });

          engagement
            .sendActivityLogged(activityLog)
            .then((res) => {
              // Show the modal but don't wait for it to close
              engagement.showPointsModalPromise(res).catch((error) => {
                console.error('Failed to show points modal:', error);
              });
              // Save activity log and log analytics immediately
              return activityLog.save();
            })
            .then(() => {
              analytics.logEvents(['webinar_video_finished'], {
                id: entry.id,
                type: entry.type,
                durationInSeconds: watchedDurationInSeconds,
                totalDuration: totalDurationInSeconds,
                percentageCompleted,
                timestamp: Date.now(),
                name: entry.defaultName.substring(0, 40),
              });
            })
            .catch((error) => {
              console.error('Error in activity log processing:', error);
            });
        }
      },
      [entry, engagement, analytics, videoMedias],
    );

    return (
      <VideoPlayerContainer backgroundImageUrl={image}>
        <KinasticVideoPlayer
          videos={videoMedias}
          showLanguageSelector={hasStarted}
          onWatchDuration={handleCreateActivityLog}
        />
        {nextMeetingDate && (
          <OverlayContainer>
            <Overlay>{nextMeetingDate.format('LT')}</Overlay>
            <Overlay style={{ marginTop: 16 }}>
              <Month>{nextMeetingDate.format('ddd')}</Month>
              <Day>{nextMeetingDate.format('DD')}</Day>
              <Month>{nextMeetingDate.format('MMM')}</Month>
            </Overlay>
          </OverlayContainer>
        )}
      </VideoPlayerContainer>
    );
  },
);
