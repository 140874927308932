/**
 * Created by katarinababic on 27.1.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PointsModel } from '../../Model/Engagement/Rewards/PointsModel';
import dayjs from 'dayjs';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Progress, ProgressProps } from 'reactstrap';
import styled from '@emotion/styled';
import { Theme } from '../../Theme/Theme';

interface ProgressBarProps extends ProgressProps {
  barColor?: string;
}

const Container = styled.div`
  padding-bottom: 16px;
`;

const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const TotalPointText = styled.span`
  color: ${(props) => Theme.Colors.primary};
  font-size: 36px;
  font-weight: 600;
`;

const TotalPointsDescriptionText = styled.span`
  color: ${(props) => Theme.Colors.primaryDarkest};
  font-size: 22px;
  margin-top: 8px;
`;

const ProgressContainer = styled.div`
  background-color: ${(props) => Theme.Colors.lighterGrey};
  border-radius: 16px;
  height: 16px;
  margin-top: 8px;
  width: 100%;
`;

const ProgressBar = styled(Progress, {
  shouldForwardProp: (prop) => !['barColor'].includes(prop),
})<ProgressBarProps>`
  height: 100%;
  border-radius: 16px;
  background-color: transparent;

  .progress-bar {
    background-color: ${(props) => props.barColor || Theme.Colors.primary};
    border-radius: 16px;
    transition: width 0.3s ease;
  }
`;

const PointsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;

const PointText = styled.span`
  font-size: 14px;
  text-align: center;
`;

const TodayText = styled.span`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export type AccountPointsSectionTotalPointsProps = {
  pointsData?: PointsModel;
};

export const AccountPointsSectionTotalPoints: React.FC<AccountPointsSectionTotalPointsProps> = observer(
  ({ pointsData }) => {
    const { t } = useTrans();

    const dailyRules = React.useMemo(
      () =>
        pointsData?.dailyRules
          ?.filter((e) => e.completed || e.canBeCompleted)
          .sort((a, b) => (!a.completed ? -1 : 0)) ?? [],
      [pointsData?.dailyRules],
    );

    const rules = dailyRules;

    const collectedPoints = rules.reduce((acc, rule) => acc + rule.collectedExperiencePoints, 0);
    const totalPoints = rules.reduce((acc, rule) => acc + rule.totalExperiencePoints, 0);

    const progress = totalPoints !== 0 ? (collectedPoints / totalPoints) * 100 : 0;

    const todayText = dayjs().format('LL');

    return (
      <Container>
        <TitleContainer>
          <TotalPointText>{(pointsData?.totalCollectedExperiencePoints ?? 0).toLocaleString()}</TotalPointText>
          <TotalPointsDescriptionText>
            {t('motivation.totalPoints', { defaultValue: 'Points in total' })}
          </TotalPointsDescriptionText>
        </TitleContainer>

        <TodayText>{todayText}</TodayText>

        <ProgressContainer>
          <ProgressBar value={Math.max(progress, 0)} max="100" barColor={Theme.Colors.primary} />
        </ProgressContainer>

        <PointsRow>
          <PointText>{collectedPoints}</PointText>
          <PointText>
            {t('challengeTab.pointsTab.totalPossiblePointsToday', { defaultValue: 'Possible points today' })}
          </PointText>
          <PointText>{totalPoints}</PointText>
        </PointsRow>
      </Container>
    );
  },
);
