/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { KinasticVideoPlayer } from '../KinasticVideoPlayer';
import { ActivityWorkoutEntry } from '../../../Model/Explore/ActivityWorkoutEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { VideoPlayerContainer } from '../../VideoPlayerContainer';
import dayjs from 'dayjs';
import { CalorieCalculator } from '../../../Services/CalorieCalculator';

export type ExploreContentViewActivityWorkoutVideoPlayerProps = {
  entry: ActivityWorkoutEntry;
};

export const ExploreContentViewActivityWorkoutVideoPlayer: React.FC<ExploreContentViewActivityWorkoutVideoPlayerProps> =
  observer(({ entry }) => {
    const { language, engagement, authentication, analytics } = useRootStore();

    const image = entry?.getImage(language.language)?.largeOrMediumOrSmallestUriObject.uri;

    const handleCreateActivityLog = React.useCallback(
      (watchedDurationInSeconds: number, totalDurationInSeconds: number) => {
        const { athlete } = authentication;
        const percentageCompleted = Math.min(1, watchedDurationInSeconds / totalDurationInSeconds);

        if (athlete && watchedDurationInSeconds > 0) {
          const calories = CalorieCalculator.instance.calculate(athlete.bmr / 24, 2.5, watchedDurationInSeconds);
          const activityLog = entry.createActivityLog(
            dayjs().subtract(watchedDurationInSeconds, 'seconds').toDate(),
            new Date(),
            {
              DURATION: watchedDurationInSeconds,
              CALORIES: calories,
              PERCENTAGE_COMPLETED: percentageCompleted,
            },
          );

          engagement
            .sendActivityLogged(activityLog)
            .then((res) => {
              // Show the modal but don't wait for it to close
              engagement.showPointsModalPromise(res).catch((error) => {
                console.error('Failed to show points modal:', error);
              });
              // Save activity log and log analytics immediately
              return activityLog.save();
            })
            .then(() => {
              analytics.logEvents(['videoWorkout_finished', 'explore_session_finished'], {
                id: entry.id,
                type: entry.type,
                durationInSeconds: watchedDurationInSeconds,
                totalDuration: totalDurationInSeconds,
                percentageCompleted,
                timestamp: Date.now(),
                name: entry.defaultName.substring(0, 40),
              });
            })
            .catch((error) => {
              console.error('Error in activity log processing:', error);
            });
        }
      },
      [entry, engagement, authentication, analytics],
    );

    return (
      <VideoPlayerContainer backgroundImageUrl={image}>
        <KinasticVideoPlayer
          videos={entry.videos}
          showLanguageSelector={true}
          onWatchDuration={handleCreateActivityLog}
        />
      </VideoPlayerContainer>
    );
  });
