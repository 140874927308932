import { action, computed, observable } from 'mobx';
import { EventData, EventProcessor } from '../Model/Engagement/Earn/EventProcessor';
import { EarnModelProcessResult } from '../Model/Engagement/Earn/EarnModelProcessResult';
import { ActivityLog } from '../Model/Activity/ActivityLog';
import { v4 as UUID } from 'uuid';
import { LocalizationStore } from './System/LocalizationStore';
import { Recipe } from '../Model/Diet/Recipe/Recipe';
import { ArticleEntry } from '../Model/Explore/ArticleEntry';
import { GymCustomerStore } from './GymCustomerStore';
import { ShopUrlResponse } from '../Model/Engagement/ShopUrlResponse';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { NewsArticle } from '../Model/News/NewsArticle';

type PointsModalData = {
  points: number;
  coins?: number;
  reason: string;
  onButtonPress?: () => void;
};

export class EngagementStore {
  @observable
  isEnabled = true;
  @observable
  isPointsModalVisible = false;
  @observable
  pointsCollected = 0;
  @observable
  pointsModalData: PointsModalData | null = null;

  constructor(readonly gymCustomer: GymCustomerStore, readonly language: LocalizationStore) {
    // config()
    //   .fetchAndActivate()
    //   .then(() => config().getBoolean('engagement_points_view_enabled'))
    //   .then((res) => runInAction(() => (this.isEnabled = res ?? false)));
  }

  shopUrl(): Promise<ShopUrlResponse> {
    return HttpBackend.get(`/engagement/loylogic/shopUrl`).then((res) => new ShopUrlResponse(res));
  }

  sendEvent(
    event: EventData,
    onPointsModalClosed?: (result?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return EventProcessor.submitEvent(event)
      .then((res) => (this.enabled ? res : new EarnModelProcessResult()))
      .then((res) => {
        if (onPointsModalClosed) {
          if (res.pointsCollected > 0) {
            this.showPointsModal(res, () => onPointsModalClosed(res));
          } else {
            onPointsModalClosed(res);
          }
        }

        return res;
      })
      .catch(() => {
        onPointsModalClosed && onPointsModalClosed();
        return new EarnModelProcessResult();
      });
  }

  sendSignup(onPointsModalClosed?: (res?: EarnModelProcessResult) => void): Promise<EarnModelProcessResult> {
    if (this.gymCustomer.customer) {
      return this.sendEvent(
        {
          event: 'signup',
          requestId: `customer-${this.gymCustomer.customer.id}`,
          // eventDateTime: this.authentication.athlete.createdAt,
        },
        onPointsModalClosed,
      );
    }
    return Promise.resolve(new EarnModelProcessResult());
  }

  sendTrackerConnected(
    provider: string,
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    if (this.gymCustomer.customer) {
      return this.sendEvent(
        {
          event: 'tracker_connected',
          requestId: `tracker-${provider}-${this.gymCustomer.customer.id}`,
          data: {
            type: provider,
            dataProvider: 'terra',
          },
        },
        onPointsModalClosed,
      );
    }
    return Promise.resolve(new EarnModelProcessResult());
  }

  sendActivityLogged(
    activityLog: ActivityLog,
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return this.sendEvent(
      {
        event: 'activity_logged',
        requestId: `activity-${activityLog.id}`,
        eventDateTime: activityLog.startDate,
        data: {
          activityLogId: activityLog.id,
          type: activityLog.type,
          value: activityLog.durationInSeconds,
          startDateTime: activityLog.startDate.toISOString(),
          endDateTime: activityLog.endDate.toISOString(),
          durationInSeconds: activityLog.durationInSeconds,
          'source.source': activityLog.source.source,
          'source.sourceId': activityLog.source.sourceId,
          'source.sourceType': activityLog.source.sourceType,
        },
      },
      onPointsModalClosed,
    );
  }

  sendFitnessProgramStarted(
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return this.sendEvent(
      {
        event: 'fitness_program_started',
        requestId: UUID(),
      },
      onPointsModalClosed,
    );
  }

  sendMindfulnessProgramStarted(
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return this.sendEvent(
      {
        event: 'mindfulness_program_started',
        requestId: UUID(),
      },
      onPointsModalClosed,
    );
  }

  sendRecipeRead(
    content: Recipe,
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return this.sendEvent(
      {
        event: 'recipe_read',
        requestId: UUID(),
        data: {
          contentId: content.id,
          contentType: content.type,
        },
      },
      onPointsModalClosed,
    );
  }

  sendArticleRead(
    content: ArticleEntry | NewsArticle,
    onPointsModalClosed?: (res?: EarnModelProcessResult) => void,
  ): Promise<EarnModelProcessResult> {
    return this.sendEvent(
      {
        event: 'article_read',
        requestId: UUID(),
        data: {
          contentId: content.id,
          contentType: 'article',
          articleSource: 'explore',
        },
      },
      onPointsModalClosed,
    );
  }

  @action
  showPointsModal(res: EarnModelProcessResult, onPointsModalClosed?: () => void) {
    this.pointsCollected = res.pointsCollected;
    this.pointsModalData = {
      points: res.pointsCollected,
      // coins: res.estimatedCoinsCollected,
      reason: res.getRuleName(this.language.language),
      onButtonPress: () => {
        this.hidePointsModal();
        onPointsModalClosed?.();
      },
    };
    this.isPointsModalVisible = true;
  }

  @action
  hidePointsModal() {
    this.isPointsModalVisible = false;
    this.pointsModalData = null;
  }

  showPointsModalPromise(res: EarnModelProcessResult): Promise<EarnModelProcessResult> {
    if (res.pointsCollected > 0) {
      return new Promise((resolve) => {
        this.showPointsModal(res, () => {
          setTimeout(() => resolve(res));
        });
      });
    }
    return Promise.resolve(res);
  }

  @computed
  get enabled(): boolean {
    return this.isEnabled;
  }
}
