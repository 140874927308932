import { EarnModelProcessResult } from './EarnModelProcessResult';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export type EarnEvents =
  | 'signup'
  | 'activity_logged'
  | 'questionnaire_answered'
  | 'fitness_program_started'
  | 'fitness_program_completed'
  | 'mindfulness_program_started'
  | 'mindfulness_program_completed'
  | 'explore_content_consumed'
  | 'explore_content_scheduled'
  | 'steps_walked'
  | 'challenged_joined'
  | 'tracker_connected'
  | 'health_data_changed'
  | 'article_read'
  | 'recipe_read'
  | 'challenge_joined'
  | 'challenge_completed'
  | 'webinar_watched';

export type EventData = {
  event: EarnEvents;
  requestId: string;
  data?: Record<string, any>;
  eventDateTime?: Date;
};

export class EventProcessor {
  static submitEvent(event: EventData): Promise<EarnModelProcessResult> {
    return HttpBackend.post(`/engagement/earn`, event).then((res) => new EarnModelProcessResult(res));
  }
}
