/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../Store/useRootStore';
import { VideoEntry } from '../../../Model/Explore/VideoEntry';
import { KinasticVideoPlayer } from '../KinasticVideoPlayer';
import { VideoPlayerContainer } from '../../VideoPlayerContainer';

export type ExploreContentViewArticleTeaserProps = {
  entry: VideoEntry;
};

export const ExploreContentViewVideoPlayer: React.FC<ExploreContentViewArticleTeaserProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

  return (
    <VideoPlayerContainer backgroundImageUrl={image}>
      <KinasticVideoPlayer videos={entry.videos} showLanguageSelector={true} />
    </VideoPlayerContainer>
  );
});
