/**
 * Created by katarinababic on 27.1.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTrans } from '../../Store/System/LocalizationStore';
import { PointsModel } from '../../Model/Engagement/Rewards/PointsModel';
import { AccountPointsSectionTotalPoints } from './AccountPointsSectionTotalPoints';
import { AccountPointsSectionPointsOverview } from './AccountPointsSectionPointsOverview';

const Title = styled.h1`
  padding-top: 16px;
`;

const Container = styled.div`
  background: #f6f6f7;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 40px;
`;

export type AccountPointsSectionProps = {};

export const AccountPointsSection: React.FC<AccountPointsSectionProps> = observer((props) => {
  const { t } = useTrans();

  const [pointsLoading, setPointsLoading] = useState(false);
  const [pointsData, setPointsData] = useState<PointsModel | undefined>();

  useEffect(() => {
    setPointsLoading(true);
    PointsModel.overview()
      .then((res) => setPointsData(res))
      .finally(() => setPointsLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Title>{t('accountScreen.points', { defaultValue: 'Points' })}</Title>
      <Container>
        <AccountPointsSectionTotalPoints pointsData={pointsData} />
        <AccountPointsSectionPointsOverview pointsData={pointsData} loading={pointsLoading} />
      </Container>
    </React.Fragment>
  );
});
