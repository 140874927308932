/**
 * Created by katarinababic on 25.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '../../../Theme/Theme';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { BreathingSession } from '../../../Model/Explore/BreathingSession';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;

const Button = styled.button<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? '#0094c1' : Theme.Colors.white)};
  color: ${({ selected }) => (selected ? Theme.Colors.white : '#0094c1')};
  padding: 8px 16px;
  border-radius: 8px;
  margin: 8px;
  border: ${({ selected }) => (selected ? 'none' : `2px solid #0094c1`)};
  font-weight: bold;
`;

export type BreathingExerciseDurationSelectionProps = {
  breathingSession: BreathingSession;
};

export const BreathingExerciseDurationSelection: React.FC<BreathingExerciseDurationSelectionProps> = observer(
  ({ breathingSession }) => {
    const { t } = useTrans();

    const [selectedDuration, setSelectedDuration] = React.useState(60);

    const handleSelectDuration = React.useCallback(
      (val: number) => {
        setSelectedDuration(val);
        breathingSession.setDuration(val);
      },
      [breathingSession],
    );

    return (
      <Container>
        <Button selected={selectedDuration === 60} onClick={() => handleSelectDuration(60)}>
          {t('breathingExercise.sessionDuration.1min')}
        </Button>
        <Button selected={selectedDuration === 180} onClick={() => handleSelectDuration(180)}>
          {t('breathingExercise.sessionDuration.3min')}
        </Button>
        <Button selected={selectedDuration === 300} onClick={() => handleSelectDuration(300)}>
          {t('breathingExercise.sessionDuration.5min')}
        </Button>
      </Container>
    );
  },
);
