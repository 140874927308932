/**
 * Created by katarinababic on 27.1.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PointsModel } from '../../Model/Engagement/Rewards/PointsModel';
import { AccountPointsSectionPointRuleEntry } from './AccountPointsSectionPointRuleEntry';
import styled from '@emotion/styled';
import { Spinner } from 'reactstrap';

const RulesContainer = styled.div`
  margin-top: 16px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSpinner = styled(Spinner)`
  height: 3rem;
  width: 3rem;
`;

export type AccountPointsSectionPointsOverviewProps = {
  pointsData?: PointsModel;
  loading?: boolean;
};

export const AccountPointsSectionPointsOverview: React.FC<AccountPointsSectionPointsOverviewProps> = observer(
  ({ pointsData, loading }) => {
    const dailyRules = React.useMemo(
      () =>
        pointsData?.dailyRules
          ?.filter((e) => e.completed || e.canBeCompleted)
          .sort((a, b) => (!a.completed ? -1 : 0)) ?? [],
      [pointsData?.dailyRules],
    );

    const rules = dailyRules;

    if (rules.length > 0) {
      return (
        <RulesContainer>
          {loading ? (
            <SpinnerContainer>
              <StyledSpinner />
            </SpinnerContainer>
          ) : (
            rules.map((rowData, index) => (
              <AccountPointsSectionPointRuleEntry key={rowData.id} rule={rowData} index={index} />
            ))
          )}
        </RulesContainer>
      );
    }

    return null;
  },
);
