/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewVideoPlayer } from '../ExploreContentViewVideo/ExploreContentViewVideoPlayer';
import { ExploreContentViewArticleContent } from './ExploreContentViewArticleContent';
import { LanguageDisclaimer } from '../LanguageDisclaimer';
import { useRootStore } from '../../../Store/useRootStore';
import styled from '@emotion/styled';

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100vh;
  padding: 0 16px;
`;

export type ExploreContentViewArticleProps = {
  entry: ArticleEntry;
};

export const ExploreContentViewArticle: React.FC<ExploreContentViewArticleProps> = observer(({ entry }) => {
  const { engagement } = useRootStore();

  const hasTriggeredRef = React.useRef(false);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Wait for layout to update
    requestAnimationFrame(() => {
      const { scrollHeight, clientHeight } = container;
      const isCloseToEnd = scrollHeight <= clientHeight + 96; // Same threshold as scroll handler

      if (isCloseToEnd && !hasTriggeredRef.current) {
        hasTriggeredRef.current = true;
        engagement.sendArticleRead(entry).then((res) => engagement.showPointsModalPromise(res));
      }
    });
  }, [entry, engagement]);

  const handleScrollEnd = React.useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      try {
        const target = event?.currentTarget;
        if (!target || hasTriggeredRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = target;
        const isCloseToEnd = scrollTop + clientHeight >= scrollHeight - 96;

        if (isCloseToEnd) {
          hasTriggeredRef.current = true;
          engagement.sendArticleRead(entry).then((res) => engagement.showPointsModalPromise(res));
        }
      } catch (error) {
        console.error('Scroll handler error:', error);
      }
    },
    [engagement, entry],
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      hasTriggeredRef.current = false;
    }, 5000); // Reset every 5 seconds if needed

    return () => clearInterval(timer);
  }, []);

  return (
    <ScrollContainer ref={scrollContainerRef} onScroll={handleScrollEnd}>
      <Container>
        <Row>
          <Col xs={12}>
            <ExploreContentViewVideoPlayer entry={entry} />
          </Col>
          {/*<Col xs={12} md={5} xl={3}>*/}
          {/*  <LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
          {/*</Col>*/}
          <Col xs={12}>
            <ExploreContentViewArticleContent entry={entry} />
          </Col>
        </Row>
      </Container>
    </ScrollContainer>
  );
});
