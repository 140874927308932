/**
 * Created by yaseminturgay on 23.06.23.
 */
import { observable } from 'mobx';
import { AuditedJson } from '../../Audited';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { PointsModelRule, PointsModelRuleJson } from './PointsModelRule';

type RewardsWalletType = {
  totalCollectedRewardCoins: number;
};

export type PointsModelJson = AuditedJson & {
  rewardsWallet: RewardsWalletType;
  collectedPoints: number;
  totalRewardsPoints: number;
  specialRules: PointsModelRuleJson[];
  dailyRules: PointsModelRuleJson[];
  totalCollectedExperiencePoints: number;
};

export class PointsModel {
  @observable
  collectedPoints = 0;
  @observable
  totalRewardsPoints = 0;
  @observable
  totalCollectedExperiencePoints = 0;
  @observable
  rewardsWallet: RewardsWalletType = { totalCollectedRewardCoins: 0 };
  @observable
  dailyRules: PointsModelRule[] = [];
  @observable
  specialRules: PointsModelRule[] = [];

  constructor(json?: Partial<PointsModelJson>) {
    if (json) {
      this.collectedPoints = json.collectedPoints ?? 0;
      this.totalRewardsPoints = json.totalRewardsPoints ?? 0;
      this.totalCollectedExperiencePoints = json.totalCollectedExperiencePoints ?? 0;
      this.rewardsWallet = json.rewardsWallet ?? { totalCollectedRewardCoins: 0 };
      this.dailyRules = json.dailyRules?.map((e) => new PointsModelRule(e)) ?? [];
      this.specialRules = json.specialRules?.map((e) => new PointsModelRule(e)) ?? [];
    }
  }

  static overview(): Promise<PointsModel> {
    return HttpBackend.get('/engagement/rewards/overview').then((res) => {
      return new PointsModel(res);
    });
  }
}
