import { LocalizationStore } from './System/LocalizationStore';
import { AuthenticationStore } from './AuthenticationStore';
import { FirebaseAuthStore } from '../Services/Firebase/FirebaseAuthStore';
import { RemoteSettingsStore } from '../Services/Firebase/RemoteSettingsStore';
import { AnalyticsStore } from './Firebase/AnalyticsStore';
import { SupportStore } from './SupportStore';
import { NotificationStore } from './Firebase/NotificationStore';
import { GymCustomerStore } from './GymCustomerStore';
import { GetStreamStore } from './Feed/GetStreamStore';
import { CoachSubscriptionStore } from './CoachSubscriptionStore';
import { EngagementStore } from './EngagementStore';

export class RootStore {
  authentication: AuthenticationStore;
  firebaseAuth: FirebaseAuthStore;
  language: LocalizationStore;
  remoteSettings: RemoteSettingsStore;
  analytics: AnalyticsStore;
  support: SupportStore;
  notification: NotificationStore;

  gymCustomer: GymCustomerStore;

  getStream: GetStreamStore;

  coachSubscription: CoachSubscriptionStore;

  engagement: EngagementStore;

  constructor() {
    this.authentication = new AuthenticationStore();
    this.firebaseAuth = new FirebaseAuthStore(this.authentication);
    this.remoteSettings = new RemoteSettingsStore(this.firebaseAuth);
    this.language = new LocalizationStore(this.remoteSettings);
    this.analytics = new AnalyticsStore(this.authentication, this.language);
    this.support = new SupportStore(this.authentication);
    this.notification = new NotificationStore(this.authentication, this.language);
    this.getStream = new GetStreamStore(this.authentication);
    this.gymCustomer = new GymCustomerStore(this.authentication, this.getStream, this.analytics);
    this.coachSubscription = new CoachSubscriptionStore(this.authentication);
    this.engagement = new EngagementStore(this.gymCustomer, this.language);
  }
}
