/**
 * Created by katarinababic on 23.1.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTrans } from '../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { Button } from './Button';
import { Theme } from '../Theme/Theme';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '../Store/useRootStore';
import { runInAction } from 'mobx';

const Title = styled.h1`
  color: ${Theme.Colors.primaryDark};
  padding-top: 32px;
`;

const Body = styled(ModalBody)`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointsImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`;

const TouchableText = styled.p`
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
  padding-top: 16px;
`;

const Footer = styled(ModalFooter)`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
`;

export type PointsCollectedModalProps = {};

export const PointsCollectedModal: React.FC<PointsCollectedModalProps> = observer((props) => {
  const { t } = useTrans();
  const navigate = useNavigate();
  const { engagement } = useRootStore();

  const handleSeeMore = React.useCallback(() => {
    runInAction(() => {
      engagement.isPointsModalVisible = false;
    });
    navigate('/account');
  }, [navigate, engagement]);

  const toggle = React.useCallback(() => {
    runInAction(() => {
      engagement.isPointsModalVisible = !engagement.isPointsModalVisible;
    });
  }, [engagement]);

  return (
    <Modal isOpen={engagement.isPointsModalVisible} toggle={toggle} fullscreen={'lg'} size={'lg'} centered={true}>
      <ModalHeader toggle={toggle}>{t('pointsModal.title', { defaultValue: 'Points collected' })}</ModalHeader>
      <Body>
        <PointsImage src={require('../Assets/Images/heart.png')} alt={'heart'} />
        <Title>
          {t('pointsModal.pointsCollected', {
            defaultValue: '+{{points}} Health Points',
            points: engagement.pointsCollected,
          })}
        </Title>
        <TouchableText onClick={handleSeeMore}>
          {t('pointsModal.seeMore', { defaultValue: 'See for what you can get health points' })}
        </TouchableText>
      </Body>
      <Footer>
        <Button color="success" onClick={toggle}>
          {t('pointsModal.awesome', { defaultValue: 'Awesome!' })}
        </Button>
      </Footer>
    </Modal>
  );
});
