export class CalorieCalculator {
  private static _instance?: CalorieCalculator;

  calculate(bmr24: number, met: number, durationSeconds: number): number {
    const timeInHours = Math.max(1, durationSeconds) / 3600.0;
    return Math.round(Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours)) * 1.2);
  }

  static get instance(): CalorieCalculator {
    if (!CalorieCalculator._instance) {
      CalorieCalculator._instance = new CalorieCalculator();
    }
    return CalorieCalculator._instance;
  }
}
